import * as React from 'react'
import {
  ICartItem,
  ICartPrice,
  IApiGetCartResponse,
  IApiQuantityCartRequestParameters,
  IApiCartItemRequestParameters,
  IApiCartGenericRequestParameters,
  ICartSummary,
  IIdentity,
} from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { IAddress, IAccount } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Account'
import {
  CMS_API_CALLS,
  ITransport,
} from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import { GERS_API_CALLS } from '../Services/GERS'
import { CMS_API_CALLS as CMS_API_CALLS_TENANT } from '../Services/CMS'
import { has } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import { AxiosResponse } from 'axios'
import { adaptUnbxdCartProduct } from '../../../utils/unbxd/unbxdHelpers'
import { setCookie, getCookie, removeCookie } from '../../../utils/cookie'
import { getUnbxdProduct } from '../../../utils/unbxd/unbxdApiCalls'
import { IValidatedItemInfo } from '../Services/GERS/Cart/cart'
import { IApiGetCartCollectionResponse } from '../Services/CMS/cart'
import { CART_COUNT_COOKIE_NAME } from '../../../settings/variables'
import { IMethodOfPayment } from '../Services/GERS/Cart/order'
import { IDates } from '../Services/GERS/Cart/delivery'
import appendQueryString from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { IUnbxdProduct } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { getCalculateCartRequest, parseCalculateCartResponse } from '../../../utils/cart/cartUtils'
import {
  getStorageValueWithExpirationDate,
  removeStorageValue,
  saveStorageValueWithExpiration,
} from '../../../utils/localStorage'
import { generateProductIdForCartItem, getSkuFromCartItem } from '../../Utils/customProductUtils'
import { isItemUnavailable, PriceType } from '../Services/GERS/Pricing/inventory'
import { getStoreCode } from '../../Utils/getStoreCodeFromCookie'

export interface IOrderSummarySG {
  title: string
  id: string
  total: number
}

export interface IExtraFees {
  title: string
  item: string
  price: number
}

export interface IMorCartPricingOptions {
  shippingMethod: string
  [x: string]: any
}

export interface IContactInfo {
  emailAddress: string
}

export interface ICalculateMorCartProps {
  cart?: ICartItem[]
  address?: IAddress
  methodsOfPayment?: IMethodOfPayment[]
  isFinanced?: boolean
  dontCallAvailableDeliveryDatesZoneCode?: boolean
  deliveryMethod?: string
  contactInfo?: IContactInfo
  saveCartDate?: Date
}

export interface IPickupDeliveryDates {
  dates: IDates[]
  selectedPickupDeliveryDate: string // This is a string Date and needs to be formatted to be shown in the UI
}

export interface IPromo {
  label?: string
  value?: number
}

interface IApiMetadataCartRequestParameters extends IApiCartGenericRequestParameters {
  productId: string
  quantity: number
  metaData?: { [x: string]: any }
  saveCartDate?: Date
}
export interface IMorCartPrice extends ICartPrice {
  merceCartId?: string
  items: any[]
  taxCharge: number
  recyclingCharge: number
  recyclingFeeInfo: string
  recyclingFeeLabel: string
  salesTaxLabel: string
  subtotalLabel: string
  pickupDeliveryLabel: string
  pickupDeliveryType: string
  pickupDeliveryDates: IPickupDeliveryDates | null
  orderTotal: number
  error?: boolean
  promoSavings: IPromo[]
  promoSavingsLabel?: string
  promoSavingsTotal?: number
  mattressDeliveryLabel?: string
  mattressDeliverySavings?: number
  couponSavings: IPromo[]
  couponSavingsTotal?: number
  addPromo1Label1?: string
  addPromo1Label2?: string
  addPromo2Label1?: string
  addPromo2Label2?: string
  addPromo3Label1?: string
  addPromo3Label2?: string
  safeguardFee?: number
  delDiscAmount: number
  taxDiscAmount: number
  customOrderSubtotal: number
  customOrderDepAmount: number
  shoppingCartId?: number
  errorCode: string
}

export interface IMorCartItem extends ICartItem {
  priceOverride?: any
}

export enum IDeliveryMethodCookieType {
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  PICKUP_STORE_CODE = 'PICKUP_STORE_CODE',
}

export enum ICartCookies {
  SAVED_CART_RETRIEVED = 'SAVED_CART_RETRIEVED',
  SAVED_CART_METADATA = 'SAVED_CART_METADATA',
}

export interface IApiMiniCartRequestParameters extends IApiCartGenericRequestParameters {
  miniCart?: boolean
  notApplyCartSummary?: boolean
}

interface IUpdateQuantityCartRequestParameters extends IApiQuantityCartRequestParameters {
  saveCartDate?: Date
}

export const SAVE_CART_EMAIL_LOCAL_STORAGE = 'SAVE_CART_EMAIL'
export const SAVE_CART_TIMESTAMP_LOCAL_STORAGE = 'SAVE_CART_TIMESTAMP'

// ---------------------------------------------------- METHOD PARAMETERS --------------------------------------------

export interface ICartMethods {
  getItems: (params: IApiMiniCartRequestParameters) => Promise<IApiGetCartResponse>
  addItemToCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  removeItemFromCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  deleteItemFromCart: (params: IApiCartItemRequestParameters) => Promise<IApiGetCartResponse>
  updateItemMetadata: (params: IApiMetadataCartRequestParameters, saveCartDate?: Date) => Promise<IApiGetCartResponse>
  mergeCart: (cartEmail: string, saveCartDate?: Date) => Promise<IApiGetCartResponse>
  overwriteCart: (cartEmail: string, saveCartDate?: Date) => Promise<IApiGetCartResponse | null>
  removeSavedCart: (cartEmail?: string) => Promise<void>
  clearCart: (params: IApiCartGenericRequestParameters) => Promise<IApiGetCartResponse>
  mapUnbxdItems: (params: ICartItem[]) => Promise<void[]>
  validateCart: (cart: ICartItem[]) => Promise<boolean>
  clearSelection?: any
  saveCart: (props: ICalculateMorCartProps) => Promise<ICartPrice>
}

// -----------------------------------------------------------------------CONTEXT-----------------------------------------------------------
export interface IMorCartContextProviderProps {
  deliveryMethod: string
  paymentMethod: string
  extraFeesArray: IExtraFees[] | undefined
  onToggleDeliveryMethod: () => void
  onTogglePaymentMethod: () => void
  getDeliveryMethod: () => string
  getPaymentMethod: () => string
  setMethodsOfPayment: (methodsOfPayment: IMethodOfPayment[]) => void
  getMethodsOfPayment: () => IMethodOfPayment[]
  getIsFinanced: () => boolean
  getCartTotal: (props: ICalculateMorCartProps) => Promise<ICartPrice>
  methods: ICartMethods
  cart: ICartItem[]
  error: string
  count: number
  cartPricing: any
  cartValidationInfo: IValidatedItemInfo[]
  makeValidateCartCall: (address: IAddress) => Promise<boolean>
  getSaveCartEmail: () => string | null
  cartHasChanged: boolean
  setCartHasChanged: (changed: boolean) => void
  updatePricing: () => void
  cartIsUpdating: boolean
}
const MorCartContext = React.createContext<IMorCartContextProviderProps | null>(null)

export interface ICartUpdatedResponse {
  items: ICartItem[]
  count: number
}

// ------------------------------------------------------------------------ CLASS ------------------------------------------------------
interface IIncomingProps {
  extraFeesArray?: IExtraFees[] | undefined
  getCartTotal: (props: ICalculateMorCartProps) => Promise<IMorCartPrice>
  eventHooksMethod?: (type: string, params?: ICartUpdatedResponse) => void
  preSetCartStateHook?: (items: ICartItem[]) => ICartItem[]
  preAddtoCartEventHook?: (cart: ICartItem[], item: { [x: string]: any }) => Promise<string>
  enableCoupon?: boolean
  transport: ITransport
  anonId: string
  account: IAccount | null
  initialCartCount?: number
  configuration?: {
    preventCartPopulationUrls?: string[]
  }
}

interface IState {
  deliveryMethod: string
  paymentMethod: string
  cart: IMorCartItem[]
  cartPricing: ICartPrice | null
  cartHydrated: boolean
  error: string
  anonId: string
  cartValidationInfo: IValidatedItemInfo[]
  methodsOfPayment: IMethodOfPayment[]
  isFinanced?: boolean
  cartHasChanged: boolean
  cartIsUpdating: boolean
}

export enum IMorShippingType {
  PICKUP = 'pickup',
  SHIPPING = 'shipping',
}

export enum IMorPaymentType {
  CREDIT_CARD = 'credit_card',
  FINANCE = 'finance',
}

class CartDataProvider extends React.Component<IIncomingProps, IState> {
  constructor(props: IIncomingProps) {
    super(props)
    this.state = {
      deliveryMethod: IMorShippingType.SHIPPING,
      paymentMethod: IMorPaymentType.CREDIT_CARD,
      cart: [],
      cartPricing: null,
      cartHydrated: false,
      error: '',
      anonId: this.props.anonId,
      cartValidationInfo: [],
      methodsOfPayment: [],
      cartHasChanged: false,
      cartIsUpdating: false,
    }
  }

  // TODO: Don't do this
  public componentDidMount = async () => {
    // Setting deliveryMethod from cookie
    const deliveryMethodType = getCookie(IDeliveryMethodCookieType.DELIVERY_TYPE, null)
    if (deliveryMethodType) {
      const deliveryMethod = deliveryMethodType === 'P' ? IMorShippingType.PICKUP : IMorShippingType.SHIPPING
      this.setState({ deliveryMethod: deliveryMethod }, () => {
        return
      })
    }
    const preventLoadUrls: string[] | null = has(this.props, ['configuration', 'preventCartPopulationUrls'])
    if (preventLoadUrls !== null) {
      if (location && location.pathname) {
        if (preventLoadUrls.indexOf(location.pathname) !== -1) {
          return
        }
      }
    }
  }

  public getIdentity = (): IIdentity => {
    const { account } = this.props
    const userId: string = account && account.user ? account.user._id : ''
    const anonId: string = this.state.anonId
    if (userId) {
      return { userId }
    }
    return {
      anonId,
    }
  }

  public setCartState = async (response: IApiGetCartResponse) => {
    if (!response.error && response.data && response.data.status !== 'error') {
      let incomingCartItems: ICartItem[] = response.data.items
      if (this.props.preSetCartStateHook) {
        incomingCartItems = this.props.preSetCartStateHook(incomingCartItems)
      }
      this.setState(
        {
          cart: incomingCartItems,
          cartHydrated: true,
        },
        () => {
          this.setCartCountInCookie()
        },
      )
    } else {
      this.setState({
        error: response.error,
      })
    }
  }

  public getSaveCartEmail = () => {
    const saveCartEmail = getStorageValueWithExpirationDate(SAVE_CART_EMAIL_LOCAL_STORAGE)
    const today = new Date()
    if (!saveCartEmail || saveCartEmail?.expirationDate < today.getTime()) {
      removeStorageValue(SAVE_CART_EMAIL_LOCAL_STORAGE)
      return ''
    }
    return saveCartEmail.data.toString()
  }

  public getSaveCartTimesamp = () => {
    const saveCartTimestamp = getStorageValueWithExpirationDate(SAVE_CART_TIMESTAMP_LOCAL_STORAGE)
    const today = new Date()
    if (!saveCartTimestamp || saveCartTimestamp?.expirationDate < today.getTime()) {
      removeStorageValue(SAVE_CART_TIMESTAMP_LOCAL_STORAGE)
      return null
    }
    return new Date(saveCartTimestamp.data.toString())
  }

  // -------------------------------------------------------  CART API METHODS ---------------------------------

  public allowAddtoCart = async (item: { [x: string]: any }): Promise<string> => {
    const preHook: any = this.props.preAddtoCartEventHook
    let errorMessage: string = ''
    if (preHook !== undefined) {
      const prehookResponse: string = await preHook(this.state.cart, item)
      if (prehookResponse !== '') {
        errorMessage = prehookResponse
        this.setState({
          error: prehookResponse,
        })
      }
    }
    return errorMessage
  }

  public saveCart = async (props: ICalculateMorCartProps): Promise<IMorCartPrice> => {
    if (!props.deliveryMethod) {
      props.deliveryMethod = this.getDeliveryMethod()
    }
    props.isFinanced = props.isFinanced || this.getIsFinanced()

    const savedTimestamp = this.getSaveCartTimesamp()
    if (!props.saveCartDate && savedTimestamp) {
      props.saveCartDate = savedTimestamp
    }

    const request = await getCalculateCartRequest(props)

    const response = await GERS_API_CALLS.CART.saveCart(request)

    return parseCalculateCartResponse(response, request, true)
  }

  public getCartTotal = async (props: ICalculateMorCartProps): Promise<IMorCartPrice> => {
    if (!props.deliveryMethod) {
      props.deliveryMethod = this.getDeliveryMethod()
    }
    props.isFinanced = props.isFinanced !== undefined ? props.isFinanced : this.getIsFinanced()
    const emailAddress = this.getSaveCartEmail()
    props.contactInfo = {
      emailAddress,
    }
    const savedTimestamp = this.getSaveCartTimesamp()
    if (!props.saveCartDate && savedTimestamp) {
      props.saveCartDate = savedTimestamp
    }
    const response: IMorCartPrice = await this.props.getCartTotal(props)
    this.setState({
      cartPricing: response,
      isFinanced: props.isFinanced,
    })

    return {
      ...response,
    }
  }

  findProductInArray = (items: ICartItem[], productId: string) => {
    const targetItem = items.find(item => generateProductIdForCartItem(item) === productId)
    return targetItem
  }

  mergeItems = (anonItems: ICartItem[], authItems: ICartItem[]) => {
    const totalArray = [...anonItems]
    authItems.forEach((item: ICartItem) => {
      const existInOtherCart = this.findProductInArray(totalArray, generateProductIdForCartItem(item))
      if (!existInOtherCart) {
        totalArray.push(item)
      } else {
        existInOtherCart.quantity += item.quantity
      }
    })

    return totalArray
  }

  public mergeCart = async (cartEmail: string, saveCartDate?: Date) => {
    const existingCollectionResponse: IApiGetCartCollectionResponse = await CMS_API_CALLS_TENANT.CART.getSavedCartCollection(
      cartEmail,
    )
    const existingCollectionId = existingCollectionResponse?.data?.[0]?.id
    const savedCartItems = existingCollectionResponse?.data?.[0].items

    if (existingCollectionId && savedCartItems) {
      saveStorageValueWithExpiration(SAVE_CART_EMAIL_LOCAL_STORAGE, cartEmail)
      if (saveCartDate) {
        saveStorageValueWithExpiration(SAVE_CART_TIMESTAMP_LOCAL_STORAGE, saveCartDate)
      }
      const currentCartItems = this.state.cart
      const mergedCartItems = this.mergeItems(savedCartItems, currentCartItems)
      const response: IApiGetCartResponse = await CMS_API_CALLS_TENANT.CART.updateSavedCartCollection(
        existingCollectionId,
        cartEmail,
        mergedCartItems,
      )
      if (response?.data?.items) {
        const savedTimestamp = this.getSaveCartTimesamp()
        if (!saveCartDate && savedTimestamp) {
          saveCartDate = savedTimestamp
        }
        for (const newItemToAdd of response.data.items) {
          await this.updateItemMetadata({
            populate: response.data.items.indexOf(newItemToAdd) === response.data.items.length - 1,
            productId: generateProductIdForCartItem(newItemToAdd),
            quantity: newItemToAdd.quantity,
            metaData: newItemToAdd.metaData,
            saveCartDate,
          })
        }
      }
      return response
    }
    return { error: '', data: null }
  }

  public overwriteCart = async (cartEmail: string, saveCartDate?: Date) => {
    const existingCollectionResponse: IApiGetCartCollectionResponse = await CMS_API_CALLS_TENANT.CART.getSavedCartCollection(
      cartEmail,
    )
    const existingCollectionId = existingCollectionResponse?.data?.[0]?.id

    if (existingCollectionId) {
      saveStorageValueWithExpiration(SAVE_CART_EMAIL_LOCAL_STORAGE, cartEmail)
      if (saveCartDate) {
        saveStorageValueWithExpiration(SAVE_CART_TIMESTAMP_LOCAL_STORAGE, saveCartDate)
      }
      const currentCartItems = this.state.cart
      const response: IApiGetCartResponse = await CMS_API_CALLS_TENANT.CART.updateSavedCartCollection(
        existingCollectionId,
        cartEmail,
        currentCartItems,
      )

      const savedTimestamp = this.getSaveCartTimesamp()
      if (!saveCartDate && savedTimestamp) {
        saveCartDate = savedTimestamp
      }

      await this.saveCart({ cart: this.state.cart, contactInfo: { emailAddress: cartEmail }, saveCartDate })

      return response
    }
    return null
  }

  public clearCart = async (params: IApiCartGenericRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.clearCart(this.props.transport, {
      populate: params.populate,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    return response
  }

  public removeSavedCart = async (cartEmail?: string) => {
    if (cartEmail) {
      const existingCollectionResponse: IApiGetCartCollectionResponse = await CMS_API_CALLS_TENANT.CART.getSavedCartCollection(
        cartEmail.toLowerCase(),
      )
      const existingCollectionId = existingCollectionResponse?.data?.[0]?.id
      if (existingCollectionId) {
        await CMS_API_CALLS_TENANT.CART.deleteSavedCartCollection(existingCollectionId)
        removeCookie(ICartCookies.SAVED_CART_RETRIEVED)
        removeCookie(ICartCookies.SAVED_CART_METADATA)
      }
    }
  }

  public getItems = async (params: IApiMiniCartRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.getItems(this.props.transport, {
      populate: params.populate,
      identity: this.getIdentity(),
    })

    if (response.data && params.populate) {
      await this.mapUnbxdItems(response.data.items)
    }
    this.setCartState(response)
    if (params.populate && response.data && !params.miniCart && !params.notApplyCartSummary) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public addItemToCart = async (params: IApiCartItemRequestParameters) => {
    const allowAdd: string = await this.allowAddtoCart(params)
    if (allowAdd !== '') {
      return {
        error: allowAdd,
        data: null,
      }
    }
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.addItemToCart(this.props.transport, {
      ...params,
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public removeItemFromCart = async (params: IApiCartItemRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.removeItemFromCart(this.props.transport, {
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    if (response.data) {
      await this.mapUnbxdItems(response.data.items)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public clearSelection = async (params: IApiCartGenericRequestParameters, productIds: any[]) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.clearSelection(
      this.props.transport,
      {
        populate: params.populate,
        identity: this.getIdentity(),
      },
      productIds,
    )
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    if (response.data) {
      await this.mapUnbxdItems(response.data.items)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public deleteItemFromCart = async (params: IApiCartItemRequestParameters) => {
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.deleteItemFromCart(this.props.transport, {
      populate: params.populate,
      productId: params.productId,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    if (response.data) {
      await this.mapUnbxdItems(response.data.items)
    }
    this.setCartState(response)
    if (params.populate && response.data) {
      await this.applyCartSummaryToState(response.data)
    }
    return response
  }

  public updateItemMetadata = async (params: IUpdateQuantityCartRequestParameters) => {
    this.setCartHasChanged(true)
    const allowAdd: string = await this.allowAddtoCart(params)
    if (allowAdd !== '') {
      return {
        error: allowAdd,
        data: null,
      }
    }
    const response: IApiGetCartResponse = await CMS_API_CALLS.CART.updateItemQuantity(this.props.transport, {
      ...params,
      populate: params.populate,
      productId: params.productId,
      quantity: params.quantity,
      identity: this.getIdentity(),
    })
    if (this.props.eventHooksMethod) {
      this.props.eventHooksMethod('updated', response.data as ICartUpdatedResponse)
    }
    if (response.data) {
      await this.mapUnbxdItems(response.data.items)
    }
    this.setCartState(response)
    return response
  }

  /**
   * Validates cart to see if items are still availble
   *
   * Intended for use when customer changes location
   * @returns {boolean} true if invalid items exist, false otherwise
   */
  public validateCart = async (cart: ICartItem[]) => {
    const storeCode = getStoreCode()
    const skus = cart.map(item => item.product.sku)

    if (skus.length > 0) {
      const priceInventorySummary = await GERS_API_CALLS.INVENTORY.getBatchPriceAndInventoryBySku({
        priceType: PriceType.WEB,
        storeCode,
        skus,
      })

      if (!priceInventorySummary.error) {
        const validatedCart = priceInventorySummary.data
        if (validatedCart) {
          const validatedItems: IValidatedItemInfo[] = validatedCart.map(item => {
            return { sku: item.sku, available: !isItemUnavailable(item) }
          })
          this.setState({ cartValidationInfo: validatedItems })
          if (validatedItems.find(item => !item.available)) {
            return true
          }
          return false
        }
        return Promise.reject('Unable to validate cart')
      }
      return Promise.reject('Unable to validate cart')
    }

    return false
  }

  /**
   * Makes a calcCart call and then validates cart
   *
   * Intended for use when customer changes location and calcCart isn't already being called
   * @returns {boolean} true if invalid items exist, false otherwise
   */
  public makeValidateCartCall = async (address: IAddress) => {
    const cart = this.state.cart
    return this.getCartTotal({
      cart,
      address,
      isFinanced: this.getIsFinanced(),
      methodsOfPayment: this.getMethodsOfPayment(),
      deliveryMethod: this.getDeliveryMethod(),
    })
      .then(() => {
        return this.validateCart(this.state.cart)
      })
      .catch((err: string) => {
        console.log(err)
        return false
      })
  }

  // -----------------------------------------------------  END CART API METHODS ---------------------------------

  public mapUnbxdItems = async (items: ICartItem[]) => {
    if (!items.length) {
      return []
    }

    let url: string = `?filter=(${items.map(item => `sku:${getSkuFromCartItem(item)}`).join('%20OR%20')})`
    url = appendQueryString(url, { variants: 'false' })
    url = appendQueryString(url, { rows: 100 })
    const productResponse: AxiosResponse = await getUnbxdProduct(url)

    const products: { [key: string]: any } = {}

    productResponse?.data?.response?.products.forEach((product: IUnbxdProduct) => {
      // The product has variants use the first variant as parent product, else use the product itself.
      products[`${product.sku}`] = adaptUnbxdCartProduct(product)
    })

    return items.map(item => {
      Object.assign(item.product, products[getSkuFromCartItem(item)])
    })
  }

  public applyCartSummaryToState = async (summary: ICartSummary, saveCartDate?: Date) => {
    const { items } = summary
    const newPricing = await this.getCartTotal({ cart: items, saveCartDate })
    this.setState({
      cartPricing: newPricing,
    })
  }

  public setMethodsOfPayment = (methodsOfPayment: IMethodOfPayment[]) => {
    this.setState({
      methodsOfPayment,
    })
  }

  public preCalculateCartTotal = async (props: ICalculateMorCartProps): Promise<ICartPrice> => {
    const items: ICartItem[] = props.cart ? props.cart : this.state.cart
    return this.getCartTotal({
      cart: items,
      address: props.address,
      isFinanced: props.isFinanced,
      methodsOfPayment: props.methodsOfPayment || this.getMethodsOfPayment(),
      deliveryMethod: props.deliveryMethod || this.getDeliveryMethod(),
      dontCallAvailableDeliveryDatesZoneCode: props.dontCallAvailableDeliveryDatesZoneCode,
    })
  }

  public getCartCount = () => {
    if (!this.state.cartHydrated) {
      const targetCount: number = this.getCartCountFromCookie()
      if (targetCount > 0) {
        return targetCount
      }
    }
    const { cart } = this.state
    const { initialCartCount } = this.props
    let count: number = 0
    for (const cartItem of cart) {
      count += cartItem.quantity
    }
    if (count > 0) {
      return count
    }
    if (initialCartCount !== undefined) {
      return initialCartCount
    }
    return count
  }

  public getCartCountFromCookie = () => {
    const cartItemCount = getCookie(CART_COUNT_COOKIE_NAME)
    if (cartItemCount !== undefined) {
      return parseInt(cartItemCount, 10)
    }
    return 0
  }

  public setCartCountInCookie = () => {
    const count: number = this.getCartCount()
    setCookie(CART_COUNT_COOKIE_NAME, count)
  }

  public getDeliveryMethod = (): string => {
    return this.state.deliveryMethod
  }

  public getPaymentMethod = (): string => {
    return this.state.paymentMethod
  }

  public getMethodsOfPayment = (): IMethodOfPayment[] => {
    return this.state.methodsOfPayment
  }

  public onToggleDeliveryMethod = () => {
    this.setCartHasChanged(true)
    if (this.state.deliveryMethod === IMorShippingType.PICKUP) {
      this.setState({ deliveryMethod: IMorShippingType.SHIPPING }, () => {
        setCookie(IDeliveryMethodCookieType.DELIVERY_TYPE, 'D')
        return
      })
    }
    if (this.state.deliveryMethod === IMorShippingType.SHIPPING) {
      this.setState({ deliveryMethod: IMorShippingType.PICKUP }, () => {
        setCookie(IDeliveryMethodCookieType.DELIVERY_TYPE, 'P')
        return
      })
    }
    return
  }

  public onTogglePaymentMethod = () => {
    if (this.state.paymentMethod === IMorPaymentType.FINANCE) {
      this.setState({ paymentMethod: IMorPaymentType.CREDIT_CARD }, () => {
        return
      })
    }
    if (this.state.paymentMethod === IMorPaymentType.CREDIT_CARD) {
      this.setState({ paymentMethod: IMorPaymentType.FINANCE }, () => {
        return
      })
    }
    return
  }

  public getIsFinanced = () => {
    return this.state.isFinanced || false
  }

  public setCartHasChanged = (changed: boolean) => {
    this.setState({
      cartHasChanged: changed,
    })
  }

  public setInMemoryCartState = async (items?: ICartItem[]) => {
    this.setCartHasChanged(true)
    if (items) {
      this.setState({
        cart: items,
      })
    }
  }

  public updatePricing = async () => {
    this.setState({
      cartIsUpdating: true,
    })
    // ---- CALCULATE CART - UPDATE PRICING AND SAVE IN SHOPPING CAR TABLE
    const newPricing = await this.getCartTotal({ cart: this.state.cart })
    this.setState({
      cartPricing: newPricing,
      cartIsUpdating: false,
      cartHasChanged: false,
    })
  }

  public render() {
    const { children } = this.props
    const exportedValues: IMorCartContextProviderProps = {
      methods: {
        getItems: this.getItems,
        clearCart: this.clearCart,
        addItemToCart: this.addItemToCart,
        removeItemFromCart: this.removeItemFromCart,
        deleteItemFromCart: this.deleteItemFromCart,
        clearSelection: this.clearSelection,
        updateItemMetadata: this.updateItemMetadata,
        mergeCart: this.mergeCart,
        overwriteCart: this.overwriteCart,
        removeSavedCart: this.removeSavedCart,
        mapUnbxdItems: this.mapUnbxdItems,
        validateCart: this.validateCart,
        saveCart: this.saveCart,
      },
      extraFeesArray: this.props.extraFeesArray,
      deliveryMethod: this.state.deliveryMethod,
      paymentMethod: this.state.paymentMethod,
      getDeliveryMethod: this.getDeliveryMethod,
      getPaymentMethod: this.getPaymentMethod,
      getMethodsOfPayment: this.getMethodsOfPayment,
      setMethodsOfPayment: this.setMethodsOfPayment,
      getIsFinanced: this.getIsFinanced,
      onToggleDeliveryMethod: this.onToggleDeliveryMethod,
      onTogglePaymentMethod: this.onTogglePaymentMethod,
      getCartTotal: this.preCalculateCartTotal,
      cart: this.state.cart,
      error: this.state.error,
      count: this.getCartCount(),
      cartPricing: this.state.cartPricing,
      cartValidationInfo: this.state.cartValidationInfo,
      cartHasChanged: this.state.cartHasChanged,
      cartIsUpdating: this.state.cartIsUpdating,
      setCartHasChanged: this.setCartHasChanged,
      updatePricing: this.updatePricing,
      makeValidateCartCall: this.makeValidateCartCall,
      getSaveCartEmail: this.getSaveCartEmail,
    }
    return <MorCartContext.Provider value={exportedValues}>{children}</MorCartContext.Provider>
  }
}

export default {
  Context: MorCartContext,
  Consumer: MorCartContext.Consumer,
  Provider: CartDataProvider,
}
